import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, TextField } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MeetingServices } from '../../../../../services';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PlanMeetingForm from '../Meetings/components/PlanMeetingForm';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

const PastMeetings = () => {
    const { t } = useTranslation();
    const loginUser = useSelector((state) => state.main.user);

    // Dünkü 00:01'den bugünkü 23:59'a tarih aralığını başlat
    const [startDate, setStartDate] = useState(() => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 1, 0, 0); // Dün saat 00:01
        return yesterday;
    });

    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        today.setHours(23, 59, 59, 999); // Bugün saat 23:59
        return today;
    });

    const [meetingRecords, setMeetingRecords] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [meetingTitle, setMeetingTitle] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.up('xs'), { defaultMatches: true });

    useEffect(() => {
        getMeetingRecords();
    }, [startDate, endDate]);

    const getMeetingRecords = () => {
        const requestModel = {
            beginning: startDate.toISOString(),
            end: endDate.toISOString(),
            pageNumber: 0,
            rowCount: 100
        };

        MeetingServices.getAllMeetingRecords(requestModel).then((response) => {
            if (response) {
                setMeetingRecords(response.data.result.items);
            }
        });
    };

    return (
        <Box>
            <Box marginBottom={7}>
                <Grid container>
                    <Grid item md={5} xs={12}>
                        <Typography
                            variant="h5"
                            color="text.tertiary"
                            sx={{ fontWeight: 700, textAlign: 'start' }}
                        >
                            {t('myPastMeeting')}
                        </Typography>
                    </Grid>

                    <Grid item md={7} container sx={{ justifyContent: 'end' }}>
                        {/* Başlangıç tarihi seçici */}
                        <DesktopDatePicker
                            maxDate={endDate}
                            disableFuture
                            name={'startDate'}
                            label={t('meetingDateStart')}
                            inputFormat="dd.MM.yyyy"
                            value={startDate}
                            onChange={(e) => {
                                const newDate = new Date(e);
                                newDate.setHours(0, 1, 0, 0); // Yeni tarih 00:01
                                setStartDate(newDate);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth={isXS}
                                    sx={{ float: 'right', width: { xs: '%100', md: 170 }, mr: 1, mt: { xs: 3, md: 0 } }}
                                    {...params}
                                />
                            )}
                        />

                        {/* Bitiş tarihi seçici */}
                        <DesktopDatePicker
                            minDate={startDate}
                            name={'endDate'}
                            label={t('meetingDateEnd')}
                            inputFormat="dd.MM.yyyy"
                            value={endDate}
                            onChange={(e) => {
                                const newDate = new Date(e);
                                newDate.setHours(23, 59, 59, 999); // Yeni tarih 23:59
                                setEndDate(newDate);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth={isXS}
                                    sx={{ float: 'right', width: { xs: '%100', md: 170 }, mr: 1, mt: { xs: 3, md: 0 } }}
                                    {...params}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container sx={{ marginTop: 5 }}>
                    {meetingRecords.map((item, i) => (
                        <Grid
                            key={i}
                            container
                            direction={'row'}
                            sx={{
                                marginTop: 2,
                                padding: 4,
                                border: '1px solid lightgray',
                                borderRadius: 3,
                                boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.48)'
                            }}
                        >
                            <Grid item md={5} xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>{t('meetingName')}</Typography>
                                <Typography>{item.meetingName}</Typography>
                            </Grid>

                            <Grid item md={4} xs={5}>
                                <Typography sx={{ fontWeight: 'bold' }}>{t('date')}</Typography>
                                <Typography>
                                    {new Date(item.meetingStartDate).toLocaleDateString()}
                                </Typography>
                            </Grid>

                            <Grid item md={2} xs={6} style={{ flex: 1 }}>
                                <Typography sx={{ fontWeight: 'bold' }}>{t('time')}</Typography>
                                <Typography>
                                    {new Date(item.meetingStartDate).toLocaleTimeString().substr(0, 5)}
                                </Typography>
                            </Grid>

                            <Grid item md={1} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                                <IconButton
                                    title={'Tekrarla'}
                                    color={'primary'}
                                    disabled={item.meetingUsers == null}
                                    onClick={() => {
                                        const filteredUsers = item.meetingUsers
                                            .filter((user, index, self) =>
                                                    index === self.findIndex(
                                                        (u) => u.id === user.id && u.eMailAddress === user.eMailAddress
                                                    )
                                            )
                                            .filter((user) => user.eMailAddress !== loginUser.email);

                                        setMeetingTitle(item.meetingName);
                                        setUserIds(
                                            filteredUsers.map((user) => ({
                                                id: user.id,
                                                fullName: user.fullName,
                                                email: user.eMailAddress
                                            }))
                                        );
                                        setModalIsOpen(true);
                                    }}
                                >
                                    <RotateRightIcon fontSize={'large'} />
                                </IconButton>
                            </Grid>

                            <Grid item md={1} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                                <IconButton
                                    color={'primary'}
                                    disabled={!item.hasRecording}
                                    onClick={() => {
                                        window.open(
                                            process.env.REACT_APP_WEBRTC_BACKEND_URL +
                                            item.recordings[0].formatItem[0].url,
                                            '_blank',
                                            'noopener,noreferrer'
                                        );
                                    }}
                                >
                                    <PlayCircleIcon fontSize={'large'} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    {meetingRecords.length === 0 && (
                        <Grid container p={10} sx={{ border: '0.1px solid lightgray', borderRadius: 2 }}>
                            <Grid item md={12} xs={12}>
                                <Typography align={'center'}>{t('noData')}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Dialog
                maxWidth={'md'}
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
            >
                <DialogContent style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}>
                    <IconButton
                        style={{
                            right: 10,
                            top: 10,
                            position: 'absolute'
                        }}
                        color={'primary'}
                        onClick={() => {
                            setModalIsOpen(false);
                        }}
                    >
                        <CloseIcon fontSize={'large'} />
                    </IconButton>
                    <PlanMeetingForm
                        getMeetings={() => {}}
                        setVisibleModal={setModalIsOpen}
                        userIds={userIds}
                        meetingTitle={meetingTitle}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default PastMeetings;
